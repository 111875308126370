<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <b-col cols="12">
          <b-form-group
            :label="tag_type.tag_type_description + ' Name'"
            label-for="tag-name"
            label-cols-md="4"
          >
            <b-form-input
              id="tag-name"
              :placeholder="tag_type.tag_type_description + ' Name'"
              v-model="form.tag_name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="tag_type.tag_type_description + ' Description'"
            label-for="tag-description"
            label-cols-md="4"
          >
            <!-- <vue-editor v-model="form.tag_description" /> -->
            <b-form-input
              id="tag-description"
              type="textarea"
              :placeholder="tag_type.tag_type_description + ' Description'"
              v-model="form.tag_description"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            :label="tag_type.tag_type_description + ' Category Title '"
            label-for="tag-cat-title"
            label-cols-md="4"
          >
            <!-- <vue-editor v-model="form.tag_description" /> -->
            <b-form-input
              id="tag-cat-title"
              :placeholder="
                tag_type.tag_type_description + ' Category Title - E.g A++'
              "
              v-model="form.tag_cat_title"
            />
          </b-form-group>
        </b-col>

        <b-col cols="12">
          <b-form-group
            :label="tag_type.tag_type_description + ' Category Percentage'"
            label-for="tag-percenatage"
            label-cols-md="4"
          >
            <!-- <vue-editor v-model="form.tag_description" /> -->
            <b-form-input
              id="tag-percenatage"
              :placeholder="
                tag_type.tag_type_description +
                ' Category % (Percentage) - E.g 45%'
              "
              v-model="form.tag_cat_percent"
            />
          </b-form-group>
        </b-col>

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            @click="onSubmit"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            @click="reset"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
} from "bootstrap-vue";
import { VueEditor } from "vue2-editor";
import Ripple from "vue-ripple-directive";
import MasterServices from "@/apiServices/MasterServices";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    VueEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tag_type: {},
      form: {
        tag_id: null,
        tag_name: null,
        tag_type_id: null,
        tag_description: null,
        tag_cat_title: null,
        tag_cat_percent: null,
      },
    };
  },
  methods: {
    async onSubmit() {
      try {
        const response = await MasterServices.saveTag(this.form);
        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Service Saved",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.go(-1);
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Service Not Saved",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        console.log("Error in saving tag ", err);
      }
    },
    reset() {
      this.form = {
        tag_id: null,
        tag_name: null,
        tag_description: null,
      };
    },
    async getDetails() {
      console.log(this.form);
      if (!this.form.tag_id) {
        return;
      }
      try {
        const response = await MasterServices.getSingleTag({
          tag_id: this.form.tag_id,
        });
        console.log(response.data);
        if (response.data.status) {
          this.form = response.data.data;
        }
      } catch (err) {
        console.log("Error in getting tag details ", err);
      }
    },
    async getTagTypeDetails(tag_type_name) {
      try {
        const response = await MasterServices.getTagTypeDetails({
          tag_type_name,
        });
        if (response.data.status) {
          this.tag_type = response.data.data;
          this.form.tag_type_id = response.data.data.tag_type_id;
        }
      } catch (err) {
        console.log("Error in getTagTypeDetails ", err);
      }
    },
  },
  beforeMount() {
    const { tag_id, tag_type_name } = this.$route.params;
    if (tag_id && !isNaN(tag_id)) {
      this.form.tag_id = tag_id;
      this.getDetails();
    }
    if (tag_type_name) {
      this.getTagTypeDetails(tag_type_name);
    }
  },
};
</script>

<style>
</style>